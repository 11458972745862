/*==================================
  INDEX
    BASE
        General Spacing
    INTRO
    SLIDE WRAPPER
        Color Blocks
    SLIDE CONTENT
        Images
    REFLECTIONS
    INTERSECTION - FADE
    SCROLL - TILT

    MEDIA QUERIES
        <= 715
        <= 400
==================================*/

/* BASE */

.project {
    position: relative;
    font-size: clamp(12px, 1vw + 1rem, 24px);
    text-align: left;
}

/* General Spacing */

.project :is(p, ul) {
    margin-bottom: 1em;
}

.project li {
    margin-bottom: 0.5em;
}


/* INTRO */

.project-intro {
    display: flex;
    position: relative;
    min-height: 15em;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center center;
    padding: 4em 4em 2em;
    margin-bottom: 8em;
    color: var(--abs-color-text-dark);
}

.project-intro::before {
    content: '';
    display: block;
    position: absolute;
    inset: 0 0 0 0;
    backdrop-filter: blur(3px);
    background: rgba(255,255,255,0.4);
}

.project-title,
.project-subtitle {
    z-index: 5;
}

.project-title {
    margin: 0;
}

.project-subtitle {
    margin-bottom: 1em;
}

.project-summary {
    position: absolute;
    top: 75%;
    max-width: 40em;
    background-color: var(--color-secondary);
    color: var(--abs-color-bg);
    padding: 1em 2em;
    border-radius: 4px;
    box-shadow: 0 0.25em 0.5em -1em rgba(50, 0, 50, 0.2), 0 1em 3em -1em rgba(50, 0, 50, 0.2);
}


/* SLIDE WRAPPER */

.slide-wrapper {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    min-height: 70vh;
    margin: 2em;
    perspective: 2000px;
    isolation: isolate;
}

.slide-wrapper::before {
    content: '';
    display: block;
    position: absolute;
    inset: 0 0 0 0;
    border-radius: 4px;
    transition: all 600ms;
}

.slide-background {
    position: absolute;
    inset: 0 0 0 0;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 4px;
    z-index: 1;
    transition: all 600ms;
}

/* Color Blocks */

.color-block {
    color: var(--abs-color-util-pure);
}

.color-block-primary::before {
    background-color: var(--color-primary);
}

.color-block-secondary::before {
    background-color: var(--color-secondary);
}

.color-block-dark::before {
    background-color: var(--abs-color-bg-dark);
}


/* SLIDE CONTENT */

.slide {
    display: flex;
    max-width: 100%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 2em;
    padding: 2em;
    z-index: 2;
    transition: all 600ms;
}

.slide.txt-img,
.slide-images.img-group {
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
}

.slide.stacked,
.slide-images.stacked {
    flex-flow: column nowrap;
    text-align: center;
}

.slide-images.stacked .image-wrapper {
    display: block;
}

.slide.txt-img .slide-notes {
    flex-basis: 35%;
    width: clamp(15em, 35%, 100%);
}

.slide.txt-img .slide-images {
    flex-basis: 60%;
}

.slide.txt-solo {
    justify-content: center;
    align-items: center;
}

.slide.txt-solo .slide-notes {
    flex-basis: 60%;
    max-width: 60em;
}

.slide-images.img-group {
    display: flex;
    align-items: center;
    gap: 1em;
}

.mobile-help-text {
    display: none;
    visibility: hidden;
}

/* Images */

.project img {
    border-radius: 4px;
}

.image-wrapper {
    display: flex;
    flex-direction: column;
}

.image-wrapper img {
    width: 100%;
}

.no-stretch img,
.no-stretch {
    max-width: min-content;
    width: auto;
    height: auto;
}


.captioned img {
    border-radius: 4px 4px 0 0;
}

.caption {
    font-size: 0.75em;
    width: 100%;
    padding: 1em 2em; 
    background-color: var(--color-bg-offset);
    color: var(--color-text-default);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.caption::before {
    content: "↳";
    margin-right: 0.5em;
}


/* REFLECTIONS */

.reflections {
    font-size: 1.1em;
    color: var(--color-util-pure);
}

.reflections .good-highlight {
    color: var(--color-secondary);
}

.reflections::before {
    background-color: var(--color-secondary);
}

.reflections .slide.txt-solo .slide-notes {
    flex-basis: 80%;
    max-width: 60em;
}

.reflections :is(.good-highlight, .bad-highlight) {
    background: var(--color-util-pure);
    padding: 0.25em 0.5em;
    border-radius: 4px;
}


/* INTERSECTION - FADE */

.fade-in {
    opacity: 0;
    transition: opacity 500ms ease-in, transform 500ms ease-out;
}

.fade-in.fade-from-left {
    transform: translateX(-3em);
}

.fade-in.fade-from-right {
    transform: translateX(3em);
}

.fade-in.fade-from-bottom {
    transform: translateY(3em);
}

.slide-wrapper:is(.slide-visited,.slide-active) .fade-in {
    transform: translate(0);
    opacity: 1;
}

/* SCROLL - TILT */

.scrolling-down .slide-tilt :is(.slide, .slide-background),
.scrolling-down .slide-tilt.slide-wrapper::before {
    transform: rotate3d(1, 0, 0, -6deg);
}

.scrolling-up  .slide-tilt :is(.slide, .slide-background),
.scrolling-up  .slide-tilt.slide-wrapper::before {
    transform: rotate3d(1, 0, 0, 6deg);
}

.scrolling-down .slide-tilt.slide-wrapper::before {
    box-shadow: 0.5em 0 0.5em -0.5em rgba(50, 0, 50, 0.2),
                -0.5em 0 0.5em -0.5em rgba(50, 0, 50, 0.2),
                3em 0 3em -3em rgba(50, 0, 50, 0.2),
                -3em 0 3em -3em rgba(50, 0, 50, 0.2),
                0 -2em 4em -3em rgba(50, 0, 50, 0.2);
}

.scrolling-up .slide-tilt.slide-wrapper::before {
    box-shadow: 0 0.5em 0.5em rgba(50, 0, 50, 0.2), 0 1em 3em -1em rgba(50, 0, 50, 0.2);
}


/* MEDIA QUERIES */

@media (width <= 1200px) {
    :is(.slide.txt-solo, .slide.txt-img) .slide-notes,
    .project :is(.slide-images,.txt-img .slide-images) {
        flex-basis: 70%;
    }
}

@media (width <= 800px) {
    .project .image-wrapper,
    .project .image-wrapper img,
    .project .image-wrapper.no-stretch,
    .project .image-wrapper.no-stretch img {
        max-width: 90vw;
        width: 90vw;
        width: 90svw;
    }

    .reflections .slide.txt-solo .slide-notes {
        flex-basis: 90%;
    }

    .project .color-block .image-wrapper,
    .project .color-block .image-wrapper img {
        width: 100%;
    }

    .mobile-help-text {
        display: block;
        visibility: visible;
    }
}

@media (width <= 715px) {
    .project-summary {
        position: relative;
        top: initial;
        width: 100%;
    }
}

@media (width <= 400px) {

    .project-intro {
        padding: 6em 2em 0;
    }

    :is(.scrolling-down, .scrolling-up) .slide-tilt :is(.slide,.slide-background),
    :is(.scrolling-down, .scrolling-up) .slide-tilt.slide-wrapper::before {
        transform: none;
        box-shadow: none;
    }
    
    .slide-wrapper {
        margin: 0.5em;
    }

    :is(.slide.txt-solo, .slide.txt-img, .reflections) .slide-notes {
        width: 100%;
        min-width: 100%;
    }

    .project :is(.slide-images,.txt-img .slide-images) {
        flex-basis: 100%;
    }

    .project .image-wrapper,
    .project .image-wrapper img,
    .project .image-wrapper.no-stretch,
    .project .image-wrapper.no-stretch img {
        max-width: 100vw;
        width: 100vw;
        width: 100svw;
    }

    .project .color-block .image-wrapper,
    .project .color-block .image-wrapper img {
        width: 100%;
    }

    .project .reflections,
    .project-summary {
        width: 100vw;
        width: 100svw;
        margin: 0;
        border-radius: 0;
        box-shadow: 10em 0 0 var(--color-secondary);
    }

    .project .reflections .slide {
        padding: 1em 0.5em;
    }


    .project .reflections::before {
        border-radius: 0;
    }
}