/*==================================
  INDEX
    BASE
    INTRO
    GALLERY
    DS CTA
    GOOD/BAD HIGHLIGHTS
==================================*/

/* BASE */

.work {
  font-size: 1rem;
  min-height: 50vh;
}


/* INTRO */

.work .intro {
  display: flex;
  flex-flow: column wrap;
  align-content: center;
  text-align: left;
  padding: 8em 2em 12em;
}

.work .intro h2 {
  font-size: 3em;
  color: var(--color-text-dark);
  margin: 0;
}

.work .intro .details {
  margin-top: 0.5em;
}

.work .intro .highlight {
  color: var(--color-primary);
  margin-right: 0.4em;
  font-weight: 100;
  font-family: var(--font-base);
}

.dark-mode .intro .highlight {
  color: var(--color-secondary);
}


/* GALLERY */

.work .gallery {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 3em;
  padding: 0 2em;
}

.work .gallery-item {
  flex: 0 0 40%;
  min-width: 20em;
  padding: 1em;
  text-align: left;
  transition: all 500ms;
  color: inherit;
}

.work .gallery-item h3 {
  font-family: var(--font-base);
}

.work .gallery-item:is(:hover,:active,:focus) {
  cursor: pointer;
  background-color: var(--color-util-pure-80);
  box-shadow: var(--shadow-default);
  border-radius: 4px;
  transform: scale(1.05);
}

.work .gallery-title {
  margin: 1em 0 0.25em;
}

.work .gallery-item.nda-locked {
  position: relative;
}

.work .gallery-item.nda-locked::before {
  content: "";
  display: block;
  position: absolute;
  top: 1.5em;
  right: 1em;
  width: 2em;
  height: 2em;
  background-image: url("/public/img/lock.svg");
  background-size: contain;
  background-repeat: no-repeat;

}


/* DS CTA */

.ds-cta {
  display: inline-block;
  padding: 1em 2em;
  background-color: var(--color-primary);
  color: var(--color-util-pure);
  margin: 8em 0 2em;
  border-radius: 4px;
  transition: all 500ms;
}

.dark-mode .ds-cta {
  background-color: var(--color-secondary);
}

.ds-cta:is(:hover,:active,:focus) {
  background-color: var(--color-primary-light);
  color: var(--color-util-pure);
}

.dark-mode .ds-cta:is(:hover,:active,:focus) {
  background-color: var(--color-secondary-light);
}

.ds-cta .arrow {
  transition: all 500ms;
}

.ds-cta:is(:hover,:active,:focus) .arrow {
  margin-left: 3em;
}


/* GOOD/BAD HIGHLIGHTS */

.good-highlight,
.bad-highlight {
  font-style: italic;
  font-weight: 600;
}

.good-highlight {
  color: var(--color-text-good);
}

.bad-highlight {
  color: var(--color-text-bad)
}