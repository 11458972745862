/*==================================
  INDEX
    HEADER LOGO
    HEADER NAV
    GALLERY
    FOOTER
    NOT FOUND (404)
==================================*/

.App {
  text-align: center;
}

/* HEADER LOGO */

.page-header {
  position: fixed;
  inset: 0 0 auto 0;
  background-color: var(--color-util-pure-80);
  display: flex;
  flex-wrap: wrap; 
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  padding: 1em 2em;
  z-index: 10;

  transition: background-color 1s;
}

.page-header:hover {
  background-color: var(--color-util-pure);
}

.page-header .title {
  display: flex;
  align-items: center;
  color: inherit;
}

.page-header .logo {
  width: 1.5em;
  border-radius: 100%;
  margin-right: 0.5em;
}

.page-header h1 {
  font-size: 1.25em;
  font-weight: 600;
}

/* HEADER NAV */

.page-header :is(nav, .non-page-nav) {
  display: flex;
  align-items: center;
}

.page-header .linkedin-link {
  display: inline-flex;
}

.page-header .nav-link {
  margin: 0 0.5em;
  color: inherit;
}

.page-header .nav-link:is(:hover,:active,:focus) {
  color: var(--color-primary-offset);
}

.page-header .linkedin-img {
  width: 1.25em;
  opacity: 0.5;
}

.dark-mode .linkedin-img {
  filter: grayscale(1) brightness(4);
}

.linkedin-link:is(:hover,:active,:focus) .linkedin-img,
.dark-mode-toggle:is(:hover,:active,:focus) img {
  opacity: 1;
}

.dark-mode-toggle {
  background: transparent;
  border: none;
  cursor: pointer;
}

.dark-mode-toggle img {
  width: 1.5em;
  opacity: 0.5;
}

.dark-mode .dark-mode-toggle img {
  filter: invert(1);
}

.page-header .non-page-nav {
  padding-left: 0.5em;
  border-left: 1px solid var(--color-text-disabled);
  margin-left: 0.5em;
}

/* GALLERY */

.gallery-title {
  font-size: 1.5em;
  font-weight: 600;
  margin-bottom: 0.25em;
}

.gallery-subtitle {
  color: var(--color-text-soft);
  margin: -0.5em 0 1em;
}

.gallery-photo img,
.gallery-video {
  border-radius: 4px;
}

.gallery-detail :is(p, ul) {
  margin-bottom: 1em;
}



/* FOOTER */

.page-footer {
  padding: 2em;
}

.dark-mode .page-footer {
  background-color: var(--color-util-pure);
}

.page-footer :is(.work-links, .play-links) {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 1em;
  margin: 1em auto;
}

.page-footer .contact-links a {
    display: inline-flex;
    margin: 0.25em 0.5em;
}

.page-footer .email-me {
  font-size: 2em;
}

.page-footer .linkedin-img {
  width: 1.5em;
}

.page-footer .copyright {
  margin-top: 2em;
}


/* NOT FOUND */

.not-found {
  margin: 8em auto;
}

.not-found h2 {
  margin-bottom: 1em;
}