/*==================================
  INDEX
    REC WRAPPER LIST
    REC LIST ITEMS
    ACTIVE REC DETAILS
==================================*/


/* REC WRAPPER LIST */

.rec-wrapper {
  background-color: var(--color-bg-offset);
}

.dark-mode .rec-wrapper {
  background-color: var(--color-util-pure);
}

.rec-list {
  display: inline-flex;
  align-items: center;
  overflow-x: auto;
}


/* REC LIST ITEMS */

.rec-item {
  flex-grow: 1;
  padding: 1em;
  border: none;
  background: transparent;
}

.rec-item:not(.active):is(:hover,:active,:focus) {
  cursor: pointer;
}

.rec-photo {
  width: clamp(3.5em, 3vw + 1rem, 5em);
  filter: grayscale(100%);
  clip-path: circle(50%);
  transition: filter, transform 1s;
}

.rec-item.active .rec-photo {
  filter: grayscale(0);
  transform: scale(1.25);
}

.rec-item:is(:hover,:active,:focus) .rec-photo {
  filter: grayscale(0);
  transform: scale(1.25);
}

.rec-photo img {
  width: 100%;
}


/* ACTIVE REC DETAILS */

.rec-detail-wrapper {
  font-size: clamp(12px, 1vw + 1rem, 18px);
  background-color: var(--abs-color-bg-dark);
  color: var(--abs-color-util-pure);
  padding: 1em 2em;
}

.rec-detail {
  width: 100%;
  max-width: 30em;
  text-align: left;
  margin: 0 auto;
}

.rec-name {
  font-weight: 700;
}

.rec-title {
  font-size: 0.9em;
  font-weight: 300;
  color: var(--color-secondary);
}

.rec-quote {
  display: block;
  margin-top: 0.5em;
}