.aria {
    /* --color-secondary: #56bb81; original green */
    --color-secondary: var(--color-primary);
}

.aria .project-summary {
    background-color: var(--color-primary);
}

.aria .aria-logo .slide {
    justify-content: center;
}

.aria .aria-logo .slide-images {
    flex-basis: auto;
}

.aria .aria-logo .slide-notes {
    flex-basis: 60%;
}

.cheers-beer {
    font-size: 2em;
}


@media (width <= 1750px) {
    .aria .review-eval.review-eval .slide {
        flex-direction: column-reverse;
        align-items: center;
    }
}

@media (width <= 1350px) {
    .aria .slide.txt-img,
    .aria .slide-images.img-group {
        flex-flow: column;
        justify-content: center;
    }

    .aria .image-wrapper.no-stretch,
    .aria .image-wrapper.no-stretch img {
        max-width: 100%;
    }
}