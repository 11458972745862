.mylw {
    --color-secondary: rgb(129, 82, 110);
}

.mylw .color-block-dark::before {
    background-color: #131415;
}

.mylw .logo-intro .slide-images {
    flex-basis: auto;
}

.dark-mode .logo-intro .slide-images {
    filter: drop-shadow(0 1px 2px #000);
}

.mylw .domain-images .image-wrapper {
    /*flex-basis: 40%;*/
    width: clamp(25em, 40%, 40em);
}


.mylw .hosting-intro {
    isolation: isolate;
}

.mylw .hosting-intro::before {
    opacity: 0.6;
    z-index: 2
}

.mylw .hosting-intro .slide-notes {
    background-color: rgba(19, 20, 21, 0.8);
    padding: 1em;
}

.mylw .quick-common :is(.image-wrapper,.slide-images) {
    max-width: 20em;
}