/*==================================
  INDEX
    COLOR OVERRIDES
    THINKING ANIM 
    ELLIPSIS ANIM
    MATRIX ANIM
    WIGGLE ICON ANIM
    MEDIA QUERIES
        <= 980
==================================*/


/* COLOR OVERRIDES */

.cohorts {
    --color-secondary: #2568fe;
}

.dark-mode .cohorts {
    --color-secondary: #3e7bc4;
}

.cohorts .customer-feedback::before {
    background-color: #1a1c1e;
}


/* THINKING ANIM */

.thinking .slide-images {
    flex-wrap: nowrap;
    font-size: clamp(0.1rem, 0.8vw + 0.1rem, 1.5rem);
}

.portrait-animation {
    position: relative;
}

.thinking .portrait-img {
    width: 45em;
}

.thinking .how-to-cohort {
    width: 45em;
}

.portrait-cover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    opacity: 0;
    transition: opacity 800ms;
}

:is(.scrolling-up, .scrolling-down) .portrait-cover {
    opacity: 1;
    transition: opacity 200ms;
}

.gear-wrapper {
    position: absolute;
    top: 12em;
    left: 16em;
    width: 16em;
}

.small-gear, 
.big-gear {
    position: relative;
    animation-iteration-count: infinite;
}

.small-gear {
    top: -0.6em;
    left: 5.5em;
    width: 5em;

    animation-name: spin-gear-alt;
    animation-duration: 6s;
}

.big-gear {
    width: 10em;

    animation-name: spin-gear;
    animation-duration: 6s;
}

@keyframes spin-gear {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes spin-gear-alt {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-720deg);
    }
}


/* ELLIPSIS ANIM */

.ellip1,
.ellip2,
.ellip3 {
    display: inline-block;
    animation-name: ellipsis-dots;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    font-size: 1.5em;
}

.ellip2 {
    animation-delay: 300ms;
}

.ellip3 {
    animation-delay: 600ms;
}

@keyframes ellipsis-dots {
    from {
        transform: translate(0,0);
    }
    50% {
        transform: translate(0,-0.1em);
        color: var(--color-primary-light)
    }
    75% {
        transform: translate(0,0);
    }
    to {
        transform: translate(0,0);
    }
}


/* MATRIX ANIM */

.matrix-bg .slide-background {
    background-position-y: 0;
    background-size: 12%;
    background-repeat: repeat;
    animation-name: matrix;
    animation-duration: 15s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-play-state: running;
}


@keyframes matrix {
    from {
        background-position-y: 100%;
    }
    to {
        background-position-y: 0;
    }
}


/* WIGGLE ICON ANIM */

.wiggle-icon {
    display: inline-block;
    position: relative;
    animation-name: wiggle;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-play-state: running;
}

.wiggle-icon-1 {
    animation-delay: 0ms;
}

.wiggle-icon-2 {
    animation-delay: 300ms;
}

.wiggle-icon-3 {
    animation-delay: 600ms;
}

@keyframes wiggle {
    from {
        transform: rotate(0);
    }
    25% {
        transform: rotate(8deg);
    }
    50% {
        transform: rotate(-8deg);
    }
    to {
        transform: rotate(0);
    }
}


/* MEDIA QUERIES */

@media (width <= 980px) {
    .cohorts .thinking .slide-images {
        flex-direction: column;
    }
}